/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Button, Tooltip, Icons, Table, FilterHeaderTable } from '~/components';

import Footer from './Footer.component';
import ArquivoForm from './arquivos/arquivos.form.component';
import { TIPO_COLUNA } from '~/modules/Helper/tipoColuna';
import { TIPO_VISAO } from '~/modules/Helper/tipo-visao';

function TableDinamic({
  dataUltimaAtualizacao,
  moduloVisao,
  handleTableChange,
  configuracao,
  quantidadeRegistros,
  setQuantidadeRegistros,
  pagina,
  setPagina,
  modulo,
  visao,
  filtro,
  setFiltro,
  periodicidadeSelecionada,
  periodoSelecionado,
  exercicioSelecionado,
  colunaFiltroVisaoAgrupamento,
  valorFiltroVisaoAgrupamento,
  tipoValorFiltroVisaoAgrupamento,
  redirecionarDetalheVisaoAnalitica,
  urlVisaoAnalitica,
  listaColunaOrdem,
  obterValoresChavesPeriodicidadePorLinha,
  redirecionarVisaoAnalitica,
  possuiArquivoDigital,
  exibirAnexos,
  filtroPesquisaArquivosDigitais,
  exibirArquivosForm,
  setFiltroPesquisaArquivosDigitais,
  setExibirArquivosForm,
  buscaAvancada,
  tableKey,
  setDataFilterHeader,
  dataFilterHeader,
  obterColunaChave,
  obterColunaLink,
}) {
  function verificaAlinhamentoColuna(tipoValor) {
    if (tipoValor === TIPO_COLUNA.DECIMAL) {
      return 'right';
    }
    if (tipoValor === TIPO_COLUNA.LINK) {
      return 'center';
    }
    return 'left';
  }

  const BotaoLink = ({ value }) => {
    return (
      <Tooltip title={value && `${value.substr(0, 50)}...`}>
        <Button
          className="btn-link-externo"
          type="primary"
          onClick={() => window.open(value, '_blank')}
          icon={<Icons.LinkOutlined />}
          shape="circle"
        />
      </Tooltip>
    );
  };

  const BotaoArquivoAnexo = props => {
    return (
      <Tooltip title="Anexos">
        <Button
          type="primary"
          icon={<Icons.FileOutlined />}
          shape="circle"
          onClick={() => exibirAnexos(props)}
        />
      </Tooltip>
    );
  };

  const BotaoRedirecionarDetalheVisaoAnalitica = ({
    valoresChavesPeriodicidade,
    value,
    exercicio,
    mes,
  }) => {
    return (
      <Button
        className="btn-chave-visao"
        type="link"
        style={{
          whiteSpace: 'none',
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
          textAlign: 'left',
        }}
        onClick={() => {
          redirecionarDetalheVisaoAnalitica(
            valoresChavesPeriodicidade,
            exercicio,
            mes
          );
        }}
      >
        <TextoValorString value={value} maxLength={100} />
      </Button>
    );
  };

  const BotaoRedirecionarVisaoAnalitica = ({ visaoColuna, value }) => {
    return (
      <Button
        className="btn-chave-visao"
        type="link"
        style={{
          whiteSpace: 'initial',
          wordBreak: 'break-word',
          textAlign: 'left',
        }}
        onClick={() => {
          redirecionarVisaoAnalitica(
            visaoColuna.FonteDados,
            value,
            visaoColuna.TipoValor
          );
        }}
      >
        <TextoValorString value={value} maxLength={100} />
      </Button>
    );
  };

  const TextoValorBoolean = ({ value }) => {
    return <span>{value === true ? 'Sim' : 'Não'}</span>;
  };

  const TextoValorString = ({ value, maxLength }) => {
    return (
      <span>
        {value?.length > maxLength
          ? `${value?.substring(0, maxLength)}...`
          : value}
      </span>
    );
  };

  const Columns = ({ row, value, visaoColuna }) => {
    const valoresChavesPeriodicidade = obterValoresChavesPeriodicidadePorLinha(
      row
    );

    if (value !== null && value !== undefined && value !== '') {
      if (
        visaoColuna.ColunaLink &&
        urlVisaoAnalitica &&
        configuracao.Tipo !== TIPO_VISAO.AGRUPAMENTO &&
        periodicidadeSelecionada
      ) {
        return (
          <BotaoRedirecionarDetalheVisaoAnalitica
            valoresChavesPeriodicidade={valoresChavesPeriodicidade}
            value={value}
            exercicio={row.Exercicio}
            mes={row.Mes}
          />
        );
      }

      if (visaoColuna.Chave && configuracao.Tipo === TIPO_VISAO.AGRUPAMENTO) {
        return (
          <BotaoRedirecionarVisaoAnalitica
            visaoColuna={visaoColuna}
            value={value}
          />
        );
      }

      if (visaoColuna.TipoValor === TIPO_COLUNA.LINK) {
        return <BotaoLink value={value} />;
      }

      if (visaoColuna.TipoValor === TIPO_COLUNA.BOOLEANO) {
        return <TextoValorBoolean value={value} />;
      }
    }

    return <TextoValorString value={value} maxLength={100} />;
  };

  function ordenacao(visaoColuna) {
    if (visaoColuna.TipoValor !== TIPO_COLUNA.LINK && visaoColuna.Ordenacao) {
      const ordenacao = listaColunaOrdem?.find(
        item => item.ColunaOrdem === visaoColuna.FonteDados
      );
      return {
        compare: (a, b) =>
          a[visaoColuna.FonteDados] - b[visaoColuna.FonteDados],
        multiple: ordenacao?.Ordem ?? visaoColuna.Ordenacao.Ordem,
      };
    }

    return visaoColuna.TipoValor !== TIPO_COLUNA.LINK;
  }

  function tipoOrdenacaoPadrao(visaoColuna) {
    const ordenacao = listaColunaOrdem?.find(
      item => item.ColunaOrdem === visaoColuna.FonteDados
    );

    if (ordenacao) return ordenacao.TipoOrdem;

    return null;
  }
  
  return (
    <>
      <Table
        key={tableKey}
        dataSource={moduloVisao.Valores}
        rowKey={row => row.Id}
        pagination={false}
        scroll={{ x: 1100 }}
        onChange={handleTableChange}
        footer={() => (
          <Footer
            configuracao={configuracao}
            moduloVisao={moduloVisao}
            quantidadeRegistros={quantidadeRegistros}
            setQuantidadeRegistros={setQuantidadeRegistros}
            page={pagina}
            setPage={setPagina}
            modulo={modulo}
            visao={visao}
            filter={filtro}
            periodicidade={periodicidadeSelecionada}
            periodo={periodoSelecionado}
            exercicio={exercicioSelecionado}
            colunaFiltroVisaoAgrupamento={colunaFiltroVisaoAgrupamento}
            valorFiltroVisaoAgrupamento={valorFiltroVisaoAgrupamento}
            tipoValorFiltroVisaoAgrupamento={tipoValorFiltroVisaoAgrupamento}
            dataUltimaAtualizacao={dataUltimaAtualizacao}
            buscaAvancada={buscaAvancada}
          />
        )}
      >
        {configuracao.VisaoColunas.map(visaoColuna => {
          return (
            <Table.Column
              key={visaoColuna?.FonteDados}
              title={visaoColuna.NomeColuna}
              dataIndex={visaoColuna?.FonteDados}
              sorter={ordenacao(visaoColuna)}
              defaultSortOrder={tipoOrdenacaoPadrao(visaoColuna)}
              align={verificaAlinhamentoColuna(visaoColuna.TipoValor)}
              filterDropdown={props => (
                <FilterHeaderTable
                  {...props}
                  visaoColuna={visaoColuna}
                  setDataFilterHeader={setDataFilterHeader}
                  dataFilterHeader={dataFilterHeader}
                  configuracao={configuracao}
                  obterColunaChave={obterColunaChave}
                  obterColunaLink={obterColunaLink}
                  setFiltro={setFiltro}
                  filtro={filtro}
                  setPagina={setPagina}
                />
              )}
              render={(value, row) => {
                return {
                  children: (
                    <Columns
                      row={row}
                      value={value}
                      visaoColuna={visaoColuna}
                    />
                  ),
                  props: {
                    'data-title': visaoColuna.NomeColuna,
                  },
                };
              }}
            />
          );
        })}

        {possuiArquivoDigital && configuracao.Tipo === TIPO_VISAO.ANALITICO ? (
          <Table.Column
            title="Anexos"
            key="Anexos"
            align="center"
            render={props => {
              return {
                children: <BotaoArquivoAnexo {...props} />,
                props: {
                  'data-title': 'Anexos',
                },
              };
            }}
          />
        ) : (
          ''
        )}
      </Table>
      {filtroPesquisaArquivosDigitais ? (
        <ArquivoForm
          visible={exibirArquivosForm}
          onCancel={() => {
            setFiltroPesquisaArquivosDigitais([]);
            setExibirArquivosForm(false);
          }}
          filtroPesquisaArquivosDigitais={filtroPesquisaArquivosDigitais}
          chaveModulo={modulo}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default TableDinamic;
