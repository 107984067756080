import styled, { createGlobalStyle, css } from 'styled-components';
import { Menu as AntDesignMenu } from 'antd';

export const Menu = styled(AntDesignMenu)`
  height: 87vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const menuTitulo = css`
  font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
  display: inline-block;
  word-wrap: break-word;
  white-space: break-spaces;
  line-height: 1.6;
`;

export const SubMenu = styled(AntDesignMenu.SubMenu)`
  .menu-titulo {
    ${menuTitulo}
  }
`;

export const MenuItem = styled(AntDesignMenu.Item)`
  .menu-titulo {
    ${menuTitulo}
  }
`;

export const MenuStyles = createGlobalStyle`

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #fff !important;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
  }
  .ant-layout-sider-children{
    position: fixed;
    width:inherit;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ::-webkit-scrollbar-track{s
    background: #f1f1f1;
    border-radius: 8px;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    line-height:normal;
    height:auto;
    margin: 10px 0px;
  }

`;
