import styled from 'styled-components';
// eslint-disable-next-line import/no-cycle
import { Button } from '../index';

export const SocialsContainer = styled.div`
  z-index: 999;
  position: fixed;
  display: flex;
  right: 0;
  bottom: 20px;
  flex-direction: column;
  top: 65%;
  transform: translateY(-65%);
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background} !important;
  border: none !important;
  margin-bottom: 10px;
  width: 32.89px;
  height: 32.89px;
  border-radius: 12px !important;
  box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.2),
    0px 4px 12px -7px rgba(0, 0, 0, 0.2) !important;
`;
